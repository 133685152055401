import 'focus-visible'
import smoothscroll from 'smoothscroll-polyfill'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ParallaxProvider } from 'react-scroll-parallax'
// const { registerLinkResolver } = require('gatsby-source-prismic-graphql')
// const { linkResolver } = require('./src/utils/linkResolver')
import { FormProvider } from './src/context/form-context'

import theme from './src/styles/theme'
import GlobalStyle from './src/styles/GlobalStyle'

import DefaultSEO from './src/components/DefaultSEO'
import Layout from './src/components/Layout'

// registerLinkResolver(linkResolver)

export const onClientEntry = () => {
  smoothscroll.polyfill()
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <FormProvider>
          <React.Fragment>
            <GlobalStyle />
            {element}
          </React.Fragment>
        </FormProvider>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

const listOfPages = [
  '/',
  '/teach-with-us/',
  '/teach-with-us',
  '/how-it-works/',
  '/how-it-works',
  '/common-questions/',
  '/common-questions',
  '/log-in/',
  '/log-in',
  '/privacy-policy/',
  '/privacy-policy',
  '/cookie-policy/',
  '/cookie-policy',
  '/TNC/',
  '/TNC',
]

export const wrapPageElement = ({ element }) => {
  var match = document.cookie.match(new RegExp('(^| )autoLogin=([^;]+)'));
  if (match && match[2] === '1') {
    if (process.env.GATSBY_ACTIVE_ENV === 'staging') {
      window.location.replace('https://toptutortest.xyz/')
    } else if (process.env.NODE_ENV === 'production') {
      window.location.replace('https://profile.oros-education.com/')
    }
  }
  let backgroundColor = undefined
  let hideNavigation = undefined
  let showApply = true
  let showFooter = true
  if (element.props.path === '/how-it-works/') {
    backgroundColor = 'dark-blue'
  } else if (element.props.path === '/log-in/') {
    backgroundColor = 'dark-blue'
    hideNavigation = true
    showApply = false
  } else if (!listOfPages.includes(element.props.path)) {
    backgroundColor = 'light-blue'
    hideNavigation = true
    showApply = false
    showFooter = false
  }

  return (
    <Layout
      backgroundColor={backgroundColor}
      hideNavigation={hideNavigation}
      showApply={showApply}
      showFooter={showFooter}
    >
      <DefaultSEO />
      {element}
    </Layout>
  )
}
