import { createGlobalStyle } from 'styled-components'
import modernNormalize from 'styled-modern-normalize'

import fonts from '../fonts/fonts'

import cursor from '../images/cursor.svg'
import dot from '../images/dot.svg'
import dotSmall from '../images/dot-small.svg'

const GlobalStyle = createGlobalStyle`
  ${modernNormalize}

  @font-face {
    font-family: 'MarkOT';
    src: url(${fonts.MarkOTExtraLightEot});
    src: url(${fonts.MarkOTExtraLightWoff2}) format('woff2'),
         url(${fonts.MarkOTExtraLightWoff}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'MarkOT';
    src: url(${fonts.MarkOTBookEot});
    src: url(${fonts.MarkOTBookWoff2}) format('woff2'),
         url(${fonts.MarkOTBookWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MarkOT';
    src: url(${fonts.MarkOTBoldEot});
    src: url(${fonts.MarkOTBoldWoff2}) format('woff2'),
         url(${fonts.MarkOTBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dignitas';
    src: url(${fonts.DignitasBoldWoff2}) format('woff2'),
         url(${fonts.DignitasBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: MarkOT, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #002C5E;
    background-color: #FDFBF9;
    background-image: url(${dotSmall});
    // cursor: url(${cursor}) 7 7, auto;
    /* overflow-y: overlay; */
    @media (min-width: 1200px) {
      background-image: url(${dot});
    }
  }

  body.no-scroll {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    // cursor: url(${cursor}) 7 7, auto !important;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    /* cursor: pointer; */
    // cursor: url(${cursor}) 7 7, auto !important;
  }

  img {
    vertical-align: middle;
  }

  figure {
    margin: 0;
  }

  blockquote {
    margin: 0;
  }

  .js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  }

  label {
    // cursor: url(${cursor}) 7 7, auto !important;
  }

  input {
    // cursor: url(${cursor}) 7 7, auto !important;
  }

  select {
    // cursor: url(${cursor}) 7 7, auto !important;
  }

  input[type='text'],
  input[type='email'] {
    appearance: none; /* for iOS */
  }

  textarea {
    appearance: none; /* for iOS */
    // cursor: url(${cursor}) 7 7, auto !important;
  }
`

export default GlobalStyle
