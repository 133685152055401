import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import Select from 'react-select'
import { StaticQuery, graphql } from 'gatsby'

import arrowWhiteRight from '../images/arrow-white-right.svg'
import plus from '../images/plus.svg'
import arrowWhiteDown from '../images/arrow-white-down.svg'
import cursorWhite from '../images/cursor-white.svg'

const ContainerOuter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.8);
  ${p => p.backgroundColor === 'dark-blue' && 'background-color: rgba(0, 44, 94, 0.8);'}
  z-index: 6;
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  z-index: 8;
  width: 100%;
  @media (min-width: 1200px) {
    max-width: 940px;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
`

const WrapperOuter = styled.div`
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1200px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

const Wrapper = styled.div`
  overflow-y: auto;
  background-color: #0859ff;
  :hover {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
`

const Content = styled.div`
  padding: 30px 48px 48px 48px;
  margin-bottom: 8px;
  color: #f1e8d9;
  @media (min-width: 1200px) {
    padding: 48px;
  }
`

const Top = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 70px;
  }
`

const CloseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: 1200px) {
    order: 1;
    width: initial;
    margin-left: auto;
  }
`

const Close = styled.button`
  position: relative;
  right: -26px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 26px;
  :after {
    content: '';
    position: absolute;
    width: 19px;
    height: 1px;
    background-color: #f1e8d9;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
  :hover {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
  @media (min-width: 1200px) {
    right: 0px;
    top: 10px;
    margin-bottom: 0px;
  }
`

const Paragraph = styled.p`
  width: 100%;
  max-width: 180px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
  @media (min-width: 600px) {
    max-width: 280px;
  }
  @media (min-width: 800px) {
    max-width: 420px;
  }
  @media (min-width: 1200px) {
    max-width: 400px;
    margin-bottom: 0px;
  }
`

const InputContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    margin-bottom: 40px;
  }
`

const Input = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 14px;
  padding: 15px 15px 15px 0px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #f1e8d9;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.43px;
  color: #f1e8d9;
  outline: none;
  ::placeholder {
    color: #f1e8d9;
  }
  :focus {
    border-bottom: 1px solid #002c5e;
  }
  :hover {
    border-bottom: 1px solid #002c5e;
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
  @media (min-width: 1200px) {
    margin-right: 22px;
    margin-bottom: 0px;
  }
`

const StyledSelect = styled(Select)`
  width: 100%;
  .react-select__control {
    height: 50px;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #f1e8d9;
    color: #f1e8d9;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.43px;
    cursor: inherit;
    position: relative;
    :after {
      content: '';
      width: 8px;
      height: 11px;
      position: absolute;
      background-image: url(${arrowWhiteDown});
      right: 10px;
      top: 18px;
      pointer-events: none;
      transition: all 0.16s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      @media (min-width: 1200px) {
        right: 22px;
        top: 20px;
      }
    }
  }
  .react-select__control:hover {
    border-bottom: 1px solid #002c5e;
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
  .react-select__control--is-focused {
    box-shadow: none;
    border-bottom: 1px solid #002c5e;
  }
  .react-select__control--menu-is-open {
    border-bottom: none !important;
    :after {
      transform: rotate(180deg);
    }
  }
  .react-select__value-container {
    padding: 15px 15px 15px 0px;
  }
  .react-select__placeholder {
    color: #f1e8d9;
  }
  .react-select__single-value {
    color: #f1e8d9;
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__menu {
    margin-top: 0px;
    border-radius: 0px;
    border-right: 1px solid #f1e8d9;
    border-bottom: 1px solid #f1e8d9;
    border-left: 1px solid #f1e8d9;
    background-color: #0859ff;
    box-shadow: none;
  }
  .react-select__menu-list {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.43px;
  }
  .react-select__option:hover {
    background-color: #002c5e;
  }
  .react-select__option--is-focused {
    background-color: #002c5e;
  }
  .react-select__option--is-selected {
    background-color: #002c5e;
  }
`

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 34px;
  @media (min-width: 1200px) {
    margin-bottom: 0px;
  }
`

const Bottom = styled.div`
  @media (min-width: 1200px) {
    display: flex;
  }
`

const BottomRight = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 36px;
  @media (min-width: 1200px) {
    margin-right: 28px;
    margin-bottom: 0px;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  min-height: 260px;
  padding: 11px 15px;
  border: 1px solid #f1e8d9;
  border-radius: 0px;
  background-color: transparent;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.43px;
  color: #f1e8d9;
  resize: none;
  outline: none;
  ::placeholder {
    color: #f1e8d9;
  }
  :focus {
    border: 1px solid #002c5e;
  }
  :hover {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
  @media (min-width: 1200px) {
    min-height: 274px;
    padding: 24px 26px;
  }
`

const CharactersLeft = styled.div`
  position: absolute;
  right: 23px;
  bottom: 21px;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.32px;
  pointer-events: none;
  @media (min-width: 1200px) {
    bottom: 26px;
  }
`

const UploadLabel = styled.label`
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 23px;
  text-align: left;
  padding: 11px 15px;
  border: 1px solid #f1e8d9;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.43px;
  @media (min-width: 1200px) {
    width: 170px;
    padding: 18px 15px;
    :hover {
      border: 1px solid #002c5e;
      // cursor: url(${cursorWhite}) 7 7, auto !important;
    }
  }
  :after {
    content: '';
    position: absolute;
    width: 9px;
    height: 10px;
    background-image: url(${plus});
    right: 21px;
    top: 14px;
    @media (min-width: 1200px) {
      right: 22px;
      top: 20px;
    }
  }
`

const FileName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`

const FileText = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.43px;
  padding-left: 15px;
  @media (min-width: 1200px) {
    max-width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const RemoveButton = styled.button`
  margin-right: 21px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.43px;
`

const RemoveImg = styled.img`
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  vertical-align: top;
  @media (min-width: 1200px) {
    :hover {
      // cursor: url(${cursorWhite}) 7 7, auto !important;
    }
  }
`

const UploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  :focus + ${UploadLabel} {
    border: 1px solid #002c5e;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1200px) {
    margin-bottom: 20px;
  }
`

const Arrow = styled.img`
  width: 34px;
  margin-left: 15px;
  transition: all 0.15s ease-out 0s;
`

const Submit = styled.button`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  @media (min-width: 1200px) {
    :hover {
      // cursor: url(${cursorWhite}) 7 7, auto !important;
      ${Arrow} {
        transform: translateX(3px);
      }
    }
  }
`

const ErrorMessage = styled.div`
  margin-bottom: 24px;
  padding: 30px 24px 30px 15px;
  background-color: #002c5e;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.36px;
  @media (min-width: 1200px) {
    margin-bottom: 14px;
    width: 170px;
  }
`

const TopSuccess = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    min-height: initial;
    flex-direction: row;
    align-items: flex-start;
  }
`

const SuccessMessageContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  @media (min-width: 1200px) {
    width: 100%;
    text-align: center;
  }
`

const SuccessMessage = styled.div`
  max-width: 160px;
  margin-bottom: 80px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  @media (min-width: 1200px) {
    max-width: initial;
    margin-top: 200px;
    margin-bottom: 20px;
  }
`

const ReturnArrow = styled.img`
  width: 34px;
  height: 8px;
  margin-right: 20px;
  transform: rotate(180deg);
  transition: all 0.15s ease-out 0s;
`

const Return = styled.button`
  width: 100%;
  max-width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 1200px) {
    max-width: initial;
    justify-content: center;
    position: relative;
    left: 80px;
    margin-bottom: 228px;
    :hover {
      // cursor: url(${cursorWhite}) 7 7, auto !important;
      ${ReturnArrow} {
        transform: rotate(180deg) translateX(3px);
      }
    }
  }
`

const globalQuery = graphql`
  query FormQuery {
    site{
      siteMetadata {
        apiUrl
      }
    }
  }
`
const Form = ({ setShowForm, backgroundColor }) => {
  const [BASE_URL, setBASE_URL] = useState('')

  const [textAreaInput, setTextAreaInput] = useState('')
  const length = get(textAreaInput, 'length')

  const [fileName, setFileName] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [email, setEmail] = useState('')
  const [first_name, setFirst_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [qualification, setQualification] = useState('')

  const uploadInputRef = useRef(null)

  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    //   "cv_link": "string",
    //   "description": "string",
    //   "email": "string",
    //   "first_name": "string",
    //   "last_name": "string",
    //   "nationality": "string",
    //   "qualification": "string"
    if (!fileUrl || !qualification.value) {
      setShowError(s => !s)
      return
    }
    let data = {
        "cv_link": fileUrl,
        "description": textAreaInput,
        "email": email,
        "first_name": first_name,
        "last_name": last_name,
        "qualification": qualification.value
    }
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if(xhr.readyState == 4){
        if((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          setShowSuccess(s => !s)
        } else {
          setShowError(s => !s)
        }
      }
    }
    xhr.open("POST", BASE_URL + "/v1/teacher_cv", true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // 发送数据
    xhr.send(JSON.stringify(data));
  }

  const removeFile = () => {
    // uploadInputRef.current.value = ''
    uploadInputRef.current.type = ''
    uploadInputRef.current.type = 'file'
    setFileUrl('')
  }

  const contentRef = useRef(null)

  useEffect(() => {
    contentRef.current.focus()
  }, [contentRef])

  return (
    <StaticQuery
      query={globalQuery}
      render={data => {
        
        setBASE_URL(data.site.siteMetadata.apiUrl)
        const options = [
          {value: 'Undergraduate', label: 'Undergraduate'},
          {value: 'Post Graduate', label: 'Post Graduate'},
          {value: 'MA', label: 'MA'},
          {value: 'PhD', label: 'PhD'},
          {value: 'Other', label: 'Other'},
        ];
        
        return (
          <ContainerOuter backgroundColor={backgroundColor}>
            <Container>
              <WrapperOuter>
                <Wrapper>
                  <Content ref={contentRef} tabIndex="-1">
                    {showSuccess ? (
                      <TopSuccess>
                        <CloseContainer>
                          <Close onClick={() => setShowForm(false)}>close</Close>
                        </CloseContainer>
                        <SuccessMessageContainer>
                          <SuccessMessage>Your application has been submitted.</SuccessMessage>
                          <Return onClick={() => setShowForm(false)}>
                            <ReturnArrow src={arrowWhiteRight} alt="" />
                            return
                          </Return>
                        </SuccessMessageContainer>
                      </TopSuccess>
                    ) : (
                      <>
                        <Top>
                          <CloseContainer>
                            <Close onClick={() => setShowForm(false)}>close</Close>
                          </CloseContainer>
                          <Paragraph>{'If you’d like to apply to join our team, please complete our form below and we will get back to you via email.'}</Paragraph>
                        </Top>
                        <form onSubmit={handleSubmit}>
                          <InputContainer>
                            <Input type="text" name="first_name" placeholder={'first name'} required
                                   value={first_name}
                                   onChange={e => {
                                     const first_name = get(e, 'target.value')
                                       setFirst_name(first_name)
                                   }} />
                            <Input type="text" name="last_name" placeholder={'last name'} required
                                   value={last_name}
                                   onChange={e => {
                                     const last_name = get(e, 'target.value')
                                     setLast_name(last_name)
                                   }} />
                            <Input name="email"
                                   type="email"
                                   placeholder={'email (University email preferred)'}
                                   required
                                   value={email}
                                   onChange={e => {
                                     const email = get(e, 'target.value')
                                     setEmail(email)
                                   }}
                            />
                            <SelectContainer>
                              <StyledSelect
                                required
                                name="qualification"
                                classNamePrefix="react-select"
                                placeholder={'qualification'}
                                isSearchable={false}
                                options={options}
                                value={qualification}
                                onChange={e => {
                                  setQualification(e)
                                }}
                              />
                            </SelectContainer>
                          </InputContainer>
                          <Bottom>
                            <TextAreaContainer>
                              <Textarea
                                name="description"
                                value={textAreaInput}
                                onChange={e => {
                                  const length = get(e, 'target.value.length')
                                  if (length <= 1000) {
                                    setTextAreaInput(e.target.value)
                                  }
                                }}
                                placeholder={
                                  'cover letter... (please include any teaching experience)'
                                }
                                required
                              />
                              <CharactersLeft>
                                {length ? length : 0}/{1000} characters
                              </CharactersLeft>
                            </TextAreaContainer>
                            <BottomRight>
                              <div>
                                <UploadInput
                                  onChange={e => {
                                    const file = get(e, 'target.files[0]')
                                    const name = get(e, 'target.files[0].name')
                                    setFileName('Uploading…')
                                    // 实例化一个AJAX对象
                                    var xhr = new XMLHttpRequest();
                                    xhr.onreadystatechange = function() {
                                      if(xhr.readyState == 4){
                                        if((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
                                          setFileUrl(JSON.parse(xhr.responseText).url)
                                          setFileName(name)
                                        } else {
                                          console.log("Request was unsuccessful:" + xhr.status);
                                          setFileName("")
                                          setFileUrl("")
                                        }
                                      }
                                    }
                                    xhr.open("POST", BASE_URL + "/v1/teacher_cv/upload", true);
                                    var formData = new FormData();
                                    formData.append('file', file);
                                    // 发送表单数据
                                    xhr.send(formData);
                                  }}
                                  ref={uploadInputRef}
                                  id="upload"
                                  type="file"
                                  required
                                />
                                <input style={{display: "none"}} name="cv_link" type="text" value={fileUrl}/>
                                <UploadLabel htmlFor="upload">{'upload C.V.'}</UploadLabel>
                                <FileName>
                                  {fileName && (
                                    <>
                                      <FileText>{fileName}</FileText>
                                      <RemoveButton
                                        onClick={() => {
                                          removeFile()
                                          setFileName('')
                                        }}
                                      >
                                        <RemoveImg src={plus} alt="Delete file" />
                                      </RemoveButton>
                                    </>
                                  )}
                                </FileName>
                              </div>
                              {showError && <ErrorMessage>Oops! Something went wrong. Please try again…</ErrorMessage>}
                              <SubmitContainer>
                                <Submit>
                                  submit <Arrow src={arrowWhiteRight} alt="" />
                                </Submit>
                              </SubmitContainer>
                            </BottomRight>
                          </Bottom>
                        </form>
                      </>
                    )}
                  </Content>
                </Wrapper>
              </WrapperOuter>
            </Container>
          </ContainerOuter>
        )
      }}
    />
  )
}

export default Form
