// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-common-questions-js": () => import("../src/pages/common-questions.js" /* webpackChunkName: "component---src-pages-common-questions-js" */),
  "component---src-pages-cookie-policy-js": () => import("../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-how-it-works-js": () => import("../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-in-js": () => import("../src/pages/log-in.js" /* webpackChunkName: "component---src-pages-log-in-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-teach-with-us-js": () => import("../src/pages/teach-with-us.js" /* webpackChunkName: "component---src-pages-teach-with-us-js" */),
  "component---src-pages-tnc-js": () => import("../src/pages/TNC.js" /* webpackChunkName: "component---src-pages-tnc-js" */)
}

