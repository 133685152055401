import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const OuterContainer = styled.div`
  background-color: #fdfbf9;
  flex: 1;
  display: flex;
  ${p => p.backgroundColor === 'dark-blue' && `background-color: #002C5E;`}
`

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: auto;
  @media (min-width: 1200px) {
    padding-left: 65px;
    padding-right: 65px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
  color: #0859ff;
  ${p => p.backgroundColor === 'dark-blue' && `color: #EFE0D7;`}
  @media (min-width: 680px) {
    padding-bottom: 30px;
  }
  @media (min-width: 1200px) {
    padding-bottom: 6px;
    flex-direction: row;
  }
`

const SocialMediaContainer = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
`

const SocialMediaItem = styled.li`
  :not(:last-child) {
    margin-right: 16px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    height: 42px;
    width: 42px;
    transform: translateY(-8px);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s ease-in-out;
    border: 1px solid transparent;
    border-radius: 100%;
    :hover {
      border: 1px solid #0859ff;
      ${p => p.backgroundColor === 'dark-blue' && 'border: 1px solid #EFE0D7;'}
    }
  }
`

const LinkContainer = styled.ul`
  display: none;
  @media (min-width: 680px) {
    display: flex;
  }
  @media (min-width: 1200px) {
    position: absolute;
    left: 0px;
    top: 31px;
  }
`

const LinkItem = styled.li`
  position: relative;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.14px;
  :not(:last-child) {
    margin-right: 40px;
  }
  :after {
    transition: 0.1s ease-in-out;
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 1px;
    background-color: #0859ff;
    ${p => p.backgroundColor === 'dark-blue' && 'background-color #EFE0D7;'}
  }
  :hover {
    :after {
      width: 30px;
    }
  }
`
export const social_media_links = [
  {url: 'https://www.twitter.com', name: 'T.W'},
  {url: 'https://www.instagram.com', name: 'I.N'},
];

const Footer = ({ backgroundColor }) => {
    

    return (
      <OuterContainer backgroundColor={backgroundColor}>
        <Container>
          <Wrapper backgroundColor={backgroundColor}>
            <SocialMediaContainer>
              {social_media_links.map((link, index) => {
                return (
                  link && (
                    <SocialMediaItem key={index} backgroundColor={backgroundColor}>
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        {link.name}
                      </a>
                    </SocialMediaItem>
                  )
                )
              })}
            </SocialMediaContainer>
            <LinkContainer>
              <LinkItem backgroundColor={backgroundColor}>
                <Link to="TNC">Tutor Terms of Service</Link>
              </LinkItem>
              <LinkItem backgroundColor={backgroundColor}>
                <Link to="privacy-policy">Privacy Policy</Link>
              </LinkItem>
            </LinkContainer>
          </Wrapper>
        </Container>
      </OuterContainer>
    )
  }

export default Footer
