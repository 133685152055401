import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #0859ff;
  position: relative;
`

const Paragraph = styled.p`
  padding: 10px 80px 7px 14px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.36px;
  color: #f1e8d9;
  span {
    margin-right: 6px;
  }
  a {
    font-weight: 700;
  }
  @media (min-width: 680px) {
    padding: 14px 135px 15px 35px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.43px;
  }
  @media (min-width: 1200px) {
    padding: 14px 250px 13px 66px;
  }
`

const Accept = styled.button`
  position: absolute;
  top: calc(50% - 4px);
  right: 19px;
  transform: translateY(-50%);
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #f1e8d9;
  :after {
    transition: 0.1s ease-in-out;
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 1px;
    background-color: #f1e8d9;
    @media (min-width: 680px) {
      width: 19px;
      bottom: -5px;
    }
  }
  @media (min-width: 680px) {
    right: 55px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
  @media (min-width: 1200px) {
    right: 102px;
  }
`

const CookieNotice = ({ acceptCookies }) => {
  return (
    <Wrapper>
      <Paragraph>
        <span>
          This website uses cookies in order to offer you the best experience. Please accept cookies for optimal
          performance.
        </span>
        <Link to="cookie-policy">More info</Link>
      </Paragraph>
      <Accept onClick={acceptCookies}>accept</Accept>
    </Wrapper>
  )
}

export default CookieNotice
