import React from 'react'
import styled from 'styled-components'
import { FormContext } from '../context/form-context'

import button from '../images/button.png'
import buttonWhite from '../images/button-white.png'
import oLetter from '../images/o.svg'
import oLetterWhite from '../images/o-white.svg'

const Text = styled.div`
  position: absolute;
  height: 70px;
  width: 70px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  justify-content: center;
  border-radius: 100%;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.08px;
  color: #0859ff;
  ${p => p.backgroundColor === 'dark-blue' && 'color: #efe0d7;'}
  transform: rotate(-90deg) translate(0px, -14px);
  transform-origin: 40% 20%;
  transition: 0.1s ease-in-out;
`

const Wrapper = styled.button`
  display: none;
  @media (min-width: 680px) {
    position: fixed;
    bottom: 32px;
    right: 50px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    :hover {
      ${Text} {
        transform: rotate(-90deg) translate(8px, -14px);
        border: 1px solid #0859ff;
        ${p => p.backgroundColor === 'dark-blue' && 'border: 1px solid #efe0d7;'}
      }
    }
  }
  @media (min-width: 1200px) {
    bottom: 32px;
    right: 40px;
    z-index: 7;
  }
  @media (min-width: 1300px) {
    right: 90px;
  }
`

const Letter = styled.img`
  width: 74px;
  position: absolute;
  top: -36px;
`

const ButtonContainer = styled.div`
  height: 70px;
  display: flex;
  align-items: flex-end;
`
const Button = styled.img`
  width: 60px;
`

const Apply = ({ backgroundColor }) => {
  const { showForm, setShowForm } = React.useContext(FormContext)
  return (
    <Wrapper showForm={showForm} backgroundColor={backgroundColor} onClick={() => setShowForm(s => !s)}>
      {showForm ? (
        <Letter src={backgroundColor === 'dark-blue' ? oLetterWhite : oLetter} />
      ) : (
        <Text backgroundColor={backgroundColor}>apply</Text>
      )}
      <ButtonContainer>
        <Button src={backgroundColor === 'dark-blue' ? buttonWhite : button} />
      </ButtonContainer>
    </Wrapper>
  )
}

export default Apply
