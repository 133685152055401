import React from 'react';
import Helmet from 'react-helmet'


const DefaultSEO = () => {
  
    const default_meta_title = 'Oros';
    const default_meta_description = `Connecting exceptional tutors to the world's most ambitious students`;
    const  default_meta_image = '';
    return (
      <Helmet>
        <title>{default_meta_title ? default_meta_title : 'Oros'}</title>
        <meta property="og:title" content={default_meta_title ? default_meta_title : 'Oros'} />
        <meta name="description" content={default_meta_description ? default_meta_description : 'Oros'} />
        <meta property="og:description" content={default_meta_description ? default_meta_description : 'Oros'} />
        {default_meta_image && default_meta_image.url && (
          <meta property="og:image" content={default_meta_image.url} />
        )}
        <meta property="og:type" content="website" />
        <meta name="author" content="Oros (https://oros-education.com/)" />
        <html lang="en" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
    )
  }

export default DefaultSEO
