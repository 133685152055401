import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Location } from '@reach/router'
import get from 'lodash.get'
import { FormContext } from '../context/form-context'
import Cookies from 'js-cookie'
import { useScroll } from '../hooks/useScroll'

import {social_media_links, footer_text, footer_link} from './Footer';

const cookieName = 'CookieConsent'
const cookieValue = true
const cookieExpires = 365

import logo from '../images/oros-logo.svg'
import logoLight from '../images/oros-logo-light.svg'
import logoLighter from '../images/oros-logo-lighter.svg'
import backButtonOnDarkBlue from '../images/back-button-on-dark-blue.svg'
import cursor from '../images/cursor.svg'
import cursorWhite from '../images/cursor-white.svg'
import CookieNotice from './CookieNotice'

import navBackgroundLight from '../images/nav-background-sq.png'
import navBackgroundLightMobile from '../images/nav-background-sq-mobile.png'
import navBackgroundDark from '../images/nav-background-dark-sq.png'
import navBackgroundDarkMobile from '../images/nav-background-dark-sq-mobile.png'

const OuterOuterContainer = styled.div`
  padding-top: 60px;
  ${p => p.cookieVisible && 'padding-top: 110px;'}
  @media (min-width: 1200px) {
    padding-top: 96px;
    ${p => p.cookieVisible && 'padding-top: 140px;'}
  }
`

const OuterContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 7;
  ${p => p.showForm && 'z-index: 1;'}
  overflow: auto;
  ${p =>
    !p.mobileMenuIsHidden &&
    css`
      @media (max-width: 1199px) {
        background: initial;
        background-color: #002c5e;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    `}
`

const NavTexture = styled.div`
  position: fixed;
  top: 0px;
  z-index: 1;
  height: 79px;
  ${p => p.cookieVisible && 'height: 129px;'}
  width: 100%;
  background-image: url(${navBackgroundLightMobile});
  ${p => p.backgroundColor === 'dark-blue' && `background: url(${navBackgroundDark});`}
  ${p => p.backgroundColor === 'light-blue' && 'background: initial;'}
  opacity: 0;
  ${p => p.scrollY > 20 && 'opacity: 1;'}
  @media (min-width: 1200px) {
    height: 109px;
  ${p => p.cookieVisible && 'height: 150px;'}
  ${p => p.scrollY > 20 && 'opacity: 0;'}
  ${p => p.scrollY > 100 && 'opacity: 1;'}
    background-image: url(${navBackgroundLight});
  ${p => p.backgroundColor === 'dark-blue' && `background: url(${navBackgroundDarkMobile});`}
  ${p => p.backgroundColor === 'light-blue' && 'background: initial;'}
  }
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: 1200px) {
    padding-left: 65px;
    padding-right: 65px;
  }
`

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 1200px) {
    padding-top: 35px;
    padding-bottom: 36px;
  }
`

const Logo = styled.img`
  height: 19px;
  @media (min-width: 680px) {
    height: 26px;
  }
  @media (min-width: 1200px) {
    height: 30px;
  }
`

const Menu = styled.ul`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
  }
`

const MenuItem = styled.li`
  font-size: 14px;
  line-height: 18px;
  color: #0859ff;
  position: relative;
  ${p => p.backgroundColor === 'dark-blue' && 'color: #efe0d7;'}
  :not(:last-child) {
    margin-right: 50px;
  }
  a {
    display: block;
    transition: 0.1s ease-in-out;
    padding: 4px;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      background-color: #0859ff;
      ${p => p.backgroundColor === 'dark-blue' && 'background-color: #efe0d7;'}
    }
    :hover {
      padding-bottom: 13px;
      :after {
        width: 30px;
        height: 2px;
      }
    }
    ${p =>
      p.active &&
      css`
        padding-bottom: 13px;
        :after {
          width: 30px;
          height: 2px;
        }
      `}
  }
`

const Button = styled.button`
  padding: 9px 31px 11px;
  border: 1px solid #0859ff;
  ${p => p.backgroundColor === 'dark-blue' && 'border: 1px solid #efe0d7;'}
  font-size: 14px;
  line-height: 1;
  transition: 0.1s ease-in-out;
  :hover {
    color: #efe0d7;
    background-color: #0859ff;
    // cursor: url(${cursorWhite}) 7 7, auto !important;
    ${p =>
      p.backgroundColor === 'dark-blue' &&
      `color: #002C5E; background-color: #efe0d7; /* cursor: url(${cursor}) 7 7, auto !important; */`}
  }
`

const InvertedButton = styled.button`
  padding: 9px 31px 11px;
  border: 1px solid #efe0d7;
  font-size: 14px;
  line-height: 1;
  @media (min-width: 680px) {
    padding: 9px 41px 11px;
  }
`

const BurgerMenuContainer = styled.div`
  height: 22px;
  margin-left: auto;
  cursor: pointer;
  ${p => !p.mobileMenuIsHidden && 'display: none;'}
  @media (min-width: 1200px) {
    display: none;
  }
`

const BurgerMenu = styled.span`
  display: block;
  width: 26px;
  height: 2px;
  background-color: #0859ff;
  ${p => p.backgroundColor === 'dark-blue' && 'background-color: #efe0d7;'}
  border-radius: 10px;
  transform: translateY(10px);
  &:before {
    content: '';
    display: block;
    width: 26px;
    height: 2px;
    background-color: #0859ff;
    ${p => p.backgroundColor === 'dark-blue' && 'background-color: #efe0d7;'}
    transform: translateY(-7px);
    border-radius: 10px;
  }
  &:after {
    content: '';
    display: block;
    width: 26px;
    height: 2px;
    background-color: #0859ff;
    ${p => p.backgroundColor === 'dark-blue' && 'background-color: #efe0d7;'}
    transform: translateY(5px);
    border-radius: 10px;
  }
`

const MobileMenu = styled.div`
  display: ${p => (p.mobileMenuIsHidden ? 'none' : 'block')};
  color: #efe0d7;
  padding-bottom: 35px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const MobileList = styled.ul`
  margin-top: 110px;
  margin-bottom: 74px;
  @media (min-width: 680px) {
    margin-top: 200px;
    margin-bottom: 80px;
  }
`

const MobileListItem = styled.li`
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
  :not(:last-child) {
    margin-bottom: 29px;
  }
  a {
    ${p => p.active && 'font-weight: 700;'}
  }
  @media (min-width: 680px) {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 0.28px;
    :not(:last-child) {
      margin-bottom: 55px;
    }
  }
`

const SocialMediaContainer = styled.ul`
  display: flex;
  margin-top: 74px;
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 18px;
  @media (min-width: 680px) {
    margin-top: 180px;
  }
`

const SocialMediaItem = styled.li`
  :not(:last-child) {
    margin-right: 26px;
  }
`

const MobileMenuFooter = styled.ul``

const MobileMenuFooterItem = styled.li`
  font-size: 9px;
  line-height: 13px;
  :not(:last-child) {
    margin-bottom: 10px;
  }
`

const Close = styled.div`
  display: none;
  position: relative;
  padding-bottom: 4px;
  color: #f1e8d9;
  ${p => !p.mobileMenuIsHidden && 'display: block;'}
  :after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 19px;
    height: 1px;
    transform: translateX(-50%);
    background-color: #f1e8d9;
  }
`

const BackButton = styled.img`
  width: 21px;
  height: 9px;
  margin-right: 8px;
  transform: translate(0px, -2px);
  transition: all 0.15s ease-out 0s;
`

const BackLink = styled(Link)`
  @media (min-width: 1200px) {
    margin-right: 25px;
    :hover {
      ${BackButton} {
        transform: translate(-3px, -2px);
      }
    }
  }
`

const Back = styled.button`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.17px;
  color: #efe0d7;
  ${p => p.backgroundColor === 'dark-blue' && 'color: #efe0d7;'}
  ${p => p.backgroundColor === 'light-blue' && 'color: #FAF6F3;'}
`

const Navbar = ({ hideNavigation, backgroundColor }) => {
  const { showForm, setShowForm } = React.useContext(FormContext)
  const [mobileMenuIsHidden, setMobileMenuIsHidden] = useState(true)
  const openMobileMenu = () => {
    setMobileMenuIsHidden(false)
    document.body.classList.add('no-scroll')
  }
  const closeMobileMenu = () => {
    setMobileMenuIsHidden(true)
    document.body.classList.remove('no-scroll')
  }

  const [cookieVisible, setCookieVisible] = useState(false)

  const acceptCookies = () => {
    Cookies.set(cookieName, cookieValue, { expires: cookieExpires })
    setCookieVisible(false)
  }

  useEffect(() => {
    if (Cookies.get(cookieName) === undefined) {
      setCookieVisible(true)
    }
  }, [])

  const { scrollY } = useScroll()
  
  return (
    <Location>
      {({ location }) => {
        const currentPath = get(location, 'pathname')
        return (
          <OuterOuterContainer cookieVisible={cookieVisible}>
            <NavTexture backgroundColor={backgroundColor} scrollY={scrollY} cookieVisible={cookieVisible} />
            <OuterContainer
              mobileMenuIsHidden={mobileMenuIsHidden}
              showForm={showForm}
              backgroundColor={backgroundColor}
            >
              {cookieVisible && <CookieNotice acceptCookies={acceptCookies} />}
              <Container>
                <NavbarContent>
                  <Link to="/" onClick={closeMobileMenu} style={{ display: 'flex' }}>
                    <Logo
                      src={
                        mobileMenuIsHidden
                          ? backgroundColor === 'dark-blue'
                            ? logoLight
                            : backgroundColor === 'light-blue'
                            ? logoLighter
                            : logo
                          : logoLight
                      }
                      alt="Logo"
                    />
                  </Link>
                  {hideNavigation ? (
                    <BackLink to="/">
                      <Back backgroundColor={backgroundColor}>
                        <BackButton src={backButtonOnDarkBlue} alt="" />
                        back
                      </Back>
                    </BackLink>
                  ) : (
                    <>
                      <Menu>
                        <MenuItem
                          active={currentPath === '/teach-with-us' || currentPath === '/teach-with-us/'}
                          backgroundColor={backgroundColor}
                        >
                          <Link to="/teach-with-us">teach with us</Link>
                        </MenuItem>
                        <MenuItem
                          active={currentPath === '/how-it-works' || currentPath === '/how-it-works/'}
                          backgroundColor={backgroundColor}
                        >
                          <Link to="/how-it-works">how it works</Link>
                        </MenuItem>
                        <MenuItem
                          active={currentPath === '/common-questions' || currentPath === '/common-questions/'}
                          backgroundColor={backgroundColor}
                        >
                          <Link to="/common-questions">common questions</Link>
                        </MenuItem>
                        <MenuItem
                          active={currentPath === '/log-in' || currentPath === '/log-in/'}
                          backgroundColor={backgroundColor}
                        >
                          {
                            process.env.GATSBY_ACTIVE_ENV === 'staging'? <a href='https://profile.toptutortest.xyz/'>log in</a> :
                              process.env.NODE_ENV === 'production' ? <a href='https://profile.oros-education.com/'>log in</a> :
                                <Link to="/log-in">log in</Link>
                          }
                        </MenuItem>
                        <MenuItem backgroundColor={backgroundColor}>
                          <Button backgroundColor={backgroundColor} onClick={() => setShowForm(true)}>
                            apply
                          </Button>
                        </MenuItem>
                      </Menu>
                      <BurgerMenuContainer
                        role="button"
                        tabIndex="0"
                        aria-label="Burger Menu"
                        onClick={openMobileMenu}
                        onKeyPress={openMobileMenu}
                        mobileMenuIsHidden={mobileMenuIsHidden}
                      >
                        <BurgerMenu backgroundColor={backgroundColor} />
                      </BurgerMenuContainer>
                      <Close mobileMenuIsHidden={mobileMenuIsHidden} onClick={closeMobileMenu}>
                        close
                      </Close>
                    </>
                  )}
                </NavbarContent>
                <MobileMenu mobileMenuIsHidden={mobileMenuIsHidden}>
                  <MobileList>
                    <MobileListItem
                      active={currentPath === '/teach-with-us' || currentPath === '/teach-with-us/'}
                    >
                      <Link to="/teach-with-us" onClick={closeMobileMenu}>
                        teach with us
                      </Link>
                    </MobileListItem>
                    <MobileListItem active={currentPath === '/how-it-works' || currentPath === '/how-it-works/'}>
                      <Link to="/how-it-works" onClick={closeMobileMenu}>
                        how it works
                      </Link>
                    </MobileListItem>
                    <MobileListItem
                      active={currentPath === '/common-questions' || currentPath === '/common-questions/'}
                    >
                      <Link to="/common-questions" onClick={closeMobileMenu}>
                        common questions
                      </Link>
                    </MobileListItem>
                    <MobileListItem active={currentPath === '/log-in' || currentPath === '/log-in/'}>
                      {
                        process.env.GATSBY_ACTIVE_ENV === 'staging'? <a href='https://profile.toptutortest.xyz/'>log in</a> :
                          process.env.NODE_ENV === 'production' ? <a href='https://profile.oros-education.com/'>log in</a> :
                            <Link to="/log-in" onClick={closeMobileMenu}>
                              log in
                            </Link>
                      }
                    </MobileListItem>
                  </MobileList>
                  <InvertedButton
                    onClick={() => {
                      closeMobileMenu()
                      setShowForm(true)
                    }}
                  >
                    apply
                  </InvertedButton>
                  <SocialMediaContainer>
                    {social_media_links.map((link, index) => {
                      const social_media_url = get(link, 'link.url')
                      const social_media_name = get(link, 'name')
                      return (
                        social_media_name && (
                          <SocialMediaItem key={index}>
                            <a href={social_media_url} target="_blank" rel="noopener noreferrer">
                              {social_media_name}
                            </a>
                          </SocialMediaItem>
                        )
                      )
                    })}
                  </SocialMediaContainer>
                  <MobileMenuFooter>
                    <MobileMenuFooterItem>
                      <Link to="/privacy-policy" onClick={closeMobileMenu}>
                        Privacy Policy
                      </Link>
                    </MobileMenuFooterItem>
                    {footer_text && (
                      <MobileMenuFooterItem>
                        <a href={footer_link} target="_blank" rel="noopener noreferrer">
                          {footer_text}
                        </a>
                      </MobileMenuFooterItem>
                    )}
                  </MobileMenuFooter>
                </MobileMenu>
              </Container>
            </OuterContainer>
          </OuterOuterContainer>
        )
      }}
    </Location>
  )
      
}

export default Navbar
