import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormContext } from '../context/form-context'

import Navbar from './Navbar'
import Footer from './Footer'
import Form from '../components/Form'
import Apply from '../components/Apply'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = ({ children, backgroundColor, hideNavigation, showApply, showFooter }) => {
  const { showForm, setShowForm } = useContext(FormContext)

  return (
    <Wrapper>
      <Navbar backgroundColor={backgroundColor} hideNavigation={hideNavigation} />
      {children}
      {showApply && <Apply backgroundColor={backgroundColor} />}
      {showForm && <Form setShowForm={setShowForm} backgroundColor={backgroundColor} />}
      {showFooter && <Footer backgroundColor={backgroundColor} hideNavigation={hideNavigation} />}
    </Wrapper>
  )
}

export default Layout
