import MarkOTBookWoff from './5b4bd9d6-75be-4d76-8292-7f6434b9e997.woff'
import MarkOTBookWoff2 from './e6beefbb-4014-4efa-961b-7762af9aefef.woff2'
import MarkOTBookEot from './3805eba7-24c2-41ba-91d2-8fa5db02bc00.eot'

import MarkOTBoldWoff from './4fe94f2e-7892-4785-9663-0350a7adf8c0.woff'
import MarkOTBoldWoff2 from './41c8162d-c2a6-49b0-8139-8bebab187588.woff2'
import MarkOTBoldEot from './8706c0b6-b423-44f7-ae1a-dd0aabd8a542.eot'

import MarkOTExtraLightWoff from './f175f556-1db5-4334-8ae7-a765441cc065.woff'
import MarkOTExtraLightWoff2 from './45af5eee-5b2e-4920-88dc-db50df86c912.woff2'
import MarkOTExtraLightEot from './a4d7f084-a16d-4350-8f8f-43f1c9a9faf0.eot'

import DignitasBoldWoff from './dignitas-bold.woff'
import DignitasBoldWoff2 from './dignitas-bold.woff2'

export default {
  MarkOTBookWoff,
  MarkOTBookWoff2,
  MarkOTBookEot,
  MarkOTBoldWoff,
  MarkOTBoldWoff2,
  MarkOTBoldEot,
  MarkOTExtraLightWoff,
  MarkOTExtraLightWoff2,
  MarkOTExtraLightEot,
  DignitasBoldWoff,
  DignitasBoldWoff2,
}
