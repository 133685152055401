/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

import { useState, useEffect } from 'react'

const isWindow = typeof window !== `undefined`
const defaultBodyOffset = { top: 0, left: 0 }

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(isWindow ? document.body.getBoundingClientRect() : defaultBodyOffset)
  const [scrollY, setScrollY] = useState(0)
  const [scrollX, setScrollX] = useState(bodyOffset.left)
  const [scrollFromBottom, setScrollFromBottom] = useState(isWindow ? bodyOffset.bottom - window.innerHeight : 0)
  const [scrollDirection, setScrollDirection] = useState()

  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect())
    setScrollY(-bodyOffset.top)
    setScrollX(bodyOffset.left)
    setScrollFromBottom(bodyOffset.bottom - window.innerHeight)
    setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
    setLastScrollTop(-bodyOffset.top)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  return {
    scrollY,
    lastScrollTop,
    scrollX,
    scrollFromBottom,
    scrollDirection,
    setScrollY,
  }
}
